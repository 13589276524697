import {
  TeliaAccordion,
  TeliaAccordionItem,
  TeliaGrid,
  TeliaHeading,
  TeliaTab,
  TeliaTabContent,
} from '@teliads/components/react';
import ReactMarkdown from 'react-markdown';

import SEO from '../../components/custom/SEO/SEO';
import HeroBanner from '../../components/voca/hero-banner/HeroBanner';
import { renderRichText } from '../../utils/rich-text-render';

import { FAQPageProps } from './models';

import './FAQPageTemplate.scss';
import Paragraph from 'components/voca/paragraph/Paragraph';

export default function FAQPageTemplate({ heroBanner, seoMetadata, sections }: FAQPageProps) {
  return (
    <>
      {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          meta={seoMetadata.meta}
          socialMedia={seoMetadata.socialMedia}
        />
      )}

      <div className="faq__hero-banner">
        <HeroBanner
          title={heroBanner.title}
          preamble={renderRichText(heroBanner.preamble)}
          video={heroBanner.imageUrl ? { url: undefined, poster: heroBanner.imageUrl } : undefined}
        />
      </div>

      <TeliaGrid className="faq">
        <TeliaTab variant="light">
          {sections.map((section) => (
            <TeliaTabContent name={section.title} key={section.title}>
              <TeliaHeading tag="h1" variant="title-400" className="faq__title">
                {section.title}
              </TeliaHeading>
              <TeliaAccordion>
                {section.items.map((qa) => (
                  <TeliaAccordionItem heading={qa.question} key={qa.question}>
                    <Paragraph key={qa.answer} variant="paragraph-200">
                      <ReactMarkdown>{qa.answer}</ReactMarkdown>
                    </Paragraph>
                  </TeliaAccordionItem>
                ))}
              </TeliaAccordion>
            </TeliaTabContent>
          ))}
        </TeliaTab>
      </TeliaGrid>
    </>
  );
}
