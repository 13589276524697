import { DocumentNode, useQuery } from '@apollo/client';
import { OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';

import { LANGUAGE_ENUM } from '../constants';

export default function useI18nQuery<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  return useQuery<TData, TVariables>(query, {
    ...options,
    variables: {
      ...(options ? options.variables : {}),
    } as TVariables & { locale: LANGUAGE_ENUM.SWEDISH },
  });
}
