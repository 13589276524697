import ReportWizard from './ReportWizard';

import { DamageReportFormProvider } from 'contexts/damage-report-form.context';

export default function DamageReportForm() {
  return (
    <DamageReportFormProvider>
      <ReportWizard />
    </DamageReportFormProvider>
  );
}
