import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import RootLayout from 'components/custom/root-layout/RootLayout';
import DamageReportForm from 'pages/DamageReportForm';
import FAQPage from 'pages/FAQ';
import Page404 from 'pages/Page404';
import PageLevel0 from 'pages/PageLevel0';

const PageLevel2 = lazy(() => import('pages/PageLevel2'));
const ErrorScreenSample = lazy(() => import('pages/ErrorScreenSample'));
const Article = lazy(() => import('pages/Article'));
const DamageReport = lazy(() => import('pages/DamageReport'));
const PageThankYou = lazy(() => import('pages/PageThankYou'));

const getChildrenRoutes = () => {
  return [
    {
      path: '',
      element: <PageLevel0 />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '/vanliga-fragor',
      element: <FAQPage />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '/anmal-skada',
      element: <DamageReport />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '/anmal-skada/skada',
      element: <DamageReportForm />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '/:slug',
      element: <PageLevel2 />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '/telia-som-natagare/:articleSlug',
      element: <Article />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '/artiklar/:articleSlug',
      element: <Article />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '/anmal-skada/tack',
      element: <PageThankYou />,
      errorElement: <ErrorScreenSample />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ];
};

const routes = [
  {
    path: `/`,
    element: <RootLayout />,
    children: getChildrenRoutes(),
    errorElement: <ErrorScreenSample />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
] as RouteObject[];

export const router = createBrowserRouter(routes);
