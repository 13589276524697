import Glide, {
  Breakpoints,
  Controls,
  Keyboard,
  Swipe,
  // @ts-ignore
} from '@glidejs/glide/dist/glide.modular.esm';
import { TeliaCol, TeliaRow } from '@teliads/components/react';
import { createRef, useEffect, useState } from 'react';

import CarouselButton from './CarouselButton';
import './GlideWrapper.scss';
import { CarouselSettings, CarouselVariant } from './types';

export default function GlideWrapper({
  variant = CarouselVariant.MEDIUM,
  titleElement,
  children,
}: CarouselSettings) {
  const sliderRef = createRef<HTMLDivElement>();
  const [showArrows, setShowArrows] = useState<boolean>(true);

  useEffect(() => {
    const breakpoints = {
      400: {
        perView: 1,
        peek: { before: 0, after: 50 },
      },
      650: {
        perView: 1,
        peek: { before: 0, after: 120 },
      },
      870: {
        perView: variant === CarouselVariant.SMALL ? 2 : 1,
        peek: { before: 0, after: variant === CarouselVariant.SMALL ? 120 : 100 },
      },
      1200: {
        perView: variant === CarouselVariant.SMALL ? 3 : 1,
        peek: { before: 0, after: variant === CarouselVariant.SMALL ? 120 : 250 },
      },
      1500: {
        perView: variant === CarouselVariant.SMALL ? 4 : 1,
        peek: { before: 0, after: variant === CarouselVariant.SMALL ? 120 : 250 },
      },
      2000: {
        perView: variant === CarouselVariant.SMALL ? 4 : 1,
        peek: { before: 0, after: variant === CarouselVariant.SMALL ? 120 : 250 },
      },
    };

    const slider = new Glide(sliderRef.current, {
      type: 'slider',
      autoplay: false,
      animationDuration: 150,
      perView: variant === CarouselVariant.SMALL ? 4 : 1,
      gap: 24,
      breakpoints,
      peek: { before: 0, after: variant === CarouselVariant.SMALL ? 120 : 300 },
      rewind: false,
      bound: true,
    }).mount({ Controls, Breakpoints, Swipe, Keyboard });

    setShowArrows(slider.settings.perView < (children?.length ?? 0));

    return () => slider.destroy();
  }, [children.length, sliderRef, variant]);

  if (children.length === 0) {
    return null;
  }

  return (
    <div className="glide-container">
      <div ref={sliderRef} className="glide">
        <TeliaRow>
          {titleElement && (
            <TeliaCol className="glide-container__title" width={12} widthMd={2}>
              {titleElement}
            </TeliaCol>
          )}

          <TeliaCol width={12} widthMd={titleElement ? 10 : 12}>
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {children.map((child, index) => (
                  <li key={index}>{child}</li>
                ))}
              </ul>
            </div>
          </TeliaCol>
        </TeliaRow>

        <TeliaRow className="glide">
          <TeliaCol width={12}>
            {showArrows && (
              <div className="glide__arrows" data-glide-el="controls">
                <CarouselButton data-testid="button-left" variant="left" />
                <CarouselButton data-testid="button-right" variant="right" />
              </div>
            )}
          </TeliaCol>
        </TeliaRow>
      </div>
    </div>
  );
}
