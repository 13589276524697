export enum LANGUAGE_ENUM {
  SWEDISH = 'sv',
}

export enum CONTENTFUL_LANGUAGE_ENUM {
  SWEDISH = 'sv',
}

export const animationDurationMs = 0.25 * 1000;

export const INTERACTIVE_ELEMENTS =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export enum KEYBOARD_KEY {
  ESCAPE = 'Escape',
}

export enum ButtonVariantEnum {
  EXPRESSIVE = 'expressive',
  SECONDARY_WHITE = 'secondary-white',
  PRIMARY = 'primary',
}

export const transitionTimingFunction = 'cubic-bezier(0.4, 0, 0.2, 1)';

export const MAX_TEXTAREA_LENGTH = 1000;
