import 'components/voca/the-header/TheHeader.scss';
import i18n from 'i18next';
import { TeliaHeader } from 'telia-navigation';

import { generateLinksWithSections } from './transformers';

import { urlLanguage } from 'apollo';
import Logo from 'components/custom/logo/Logo';

export default function TheHeader() {
  return (
    <div className="the-header">
      <TeliaHeader
        links={generateLinksWithSections(urlLanguage())}
        i18n={i18n}
        isSticky
        logo={<Logo />}
      />
    </div>
  );
}
