import { Suspense, useEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';

import MobileHeader from '../mobile-header/MobileHeader';

import { TheFooter } from 'components/voca/the-footer';
import TheHeader from 'components/voca/the-header/TheHeader';
import useWindowSize from 'utils/hooks/useWindowSize';

import 'components/custom/root-layout/RootLayout.scss';

export default function RootLayout() {
  const MIN_DESKTOP_WIDTH = 1200;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.endsWith('/')) {
      navigate(location.pathname.slice(0, -1));
    }
  }, [location.pathname, navigate]);

  const [windowWidth] = useWindowSize();

  return (
    <div className="root-layout">
      {windowWidth < MIN_DESKTOP_WIDTH ? <MobileHeader /> : <TheHeader />}
      <ScrollRestoration />

      <main className="root-layout__main">
        <Suspense>
          <Outlet />
        </Suspense>
      </main>

      <TheFooter />
    </div>
  );
}
