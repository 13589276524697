import { TeliaImage, TeliaLinkImage } from '@teliads/components/react';

interface Props {
  imgSrc: string;
  alt: string;
  hyperlink: string | null;
}

export default function ImageAssetContainer({ imgSrc, alt, hyperlink }: Props) {
  return (
    <>
      {hyperlink ? (
        <TeliaLinkImage
          htmlHref={hyperlink}
          linkText={alt}
          className="richtext-element"
          dataTestid="richtext-telia-image"
        >
          <TeliaImage
            src={imgSrc}
            alt={alt}
            variant="scalable"
            className="richtext-element"
            dataTestid="richtext-telia-image"
          />
        </TeliaLinkImage>
      ) : (
        <TeliaImage
          src={imgSrc}
          alt={alt}
          variant="scalable"
          className="richtext-element"
          dataTestid="richtext-telia-image"
        />
      )}
    </>
  );
}
