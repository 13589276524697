import { ReactNode } from 'react';

export enum CarouselVariant {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export type CarouselSettings = {
  children: ReactNode[];
  titleElement?: ReactNode;
  variant?: CarouselVariant;
};
