// eslint-disable-next-line import/order
import {
  TeliaCol,
  TeliaDatePicker,
  TeliaDivider,
  TeliaHeading,
  TeliaRadioButton,
  TeliaRow,
  TeliaTextInput,
  TeliaTextarea,
  TeliaToggle,
} from '@teliads/components/react';
import '../StepOneForm.scss';

import { useTranslation } from 'react-i18next';

import { useDamageReportForm } from '../../../../contexts/damage-report-form.context';
import { MAX_TEXTAREA_LENGTH } from '../../../../utils/constants';
import { GuideCorrectOptions, CopperOptions, FiberOptions } from '../models';

import Paragraph from 'components/voca/paragraph/Paragraph';

function GroundCable({ updateInput }: any) {
  const { stepOne } = useDamageReportForm();
  const currentDate = new Date().toLocaleDateString('sv-SE', { timeZone: 'CET' });
  const { t } = useTranslation();

  return (
    <>
      <TeliaHeading variant="subsection-100" tag="h3" data-testid="step-one-form-part-two">
        {`2. ${t('damage_report_form.step_one.management_kind')}?`}
      </TeliaHeading>

      <TeliaRow class="step-one-form__radio-section">
        <TeliaCol width={12} widthMd={5} className="ground_fiber">
          <Paragraph variant="overline-100" className="step-one-form__divider">
            {t('damage_report_form.step_one.fiber')}
          </Paragraph>
          <TeliaDivider />

          <div className="step-one-form__radio-buttons">
            {Object.entries(FiberOptions).map(([key, value]) => (
              <TeliaRadioButton
                key={key}
                checked={stepOne.damage.fiber === t(value)}
                onClick={() =>
                  updateInput('damage', {
                    copper: stepOne.damage.copper,
                    fiber: t(value),
                  })
                }
              >
                {t(value)}
              </TeliaRadioButton>
            ))}
          </div>
        </TeliaCol>

        <TeliaCol width={12} widthMd={5} className="step">
          <Paragraph variant="overline-100" className="step-one-form__divider">
            {t('damage_report_form.step_one.copper')}
          </Paragraph>
          <TeliaDivider />

          <div className="step-one-form__radio-buttons">
            {Object.entries(CopperOptions).map(([key, value]) => (
              <TeliaRadioButton
                key={key}
                checked={stepOne.damage.copper === t(value)}
                onClick={() =>
                  updateInput('damage', {
                    copper: t(value),
                    fiber: stepOne.damage.fiber,
                  })
                }
              >
                {t(value)}
              </TeliaRadioButton>
            ))}
          </div>
        </TeliaCol>
      </TeliaRow>

      <TeliaRow>
        <TeliaCol width={12} widthMd={6}>
          <TeliaTextarea
            maxlength={MAX_TEXTAREA_LENGTH}
            required
            requiredErrorMessage={t('error_message.required')}
            label={`3. ${t('damage_report_form.step_one.damage_description')}`}
            value={stepOne.description}
            onInput={(e) => updateInput('description', (e.target as HTMLInputElement).value.trim())}
            rows={2}
          />
        </TeliaCol>
      </TeliaRow>

      <TeliaRow>
        <TeliaCol width={12} widthMd={6}>
          <TeliaTextInput
            type="text"
            maxlength={MAX_TEXTAREA_LENGTH}
            value={stepOne.cableWidth}
            placeholder="120 mm / 3 Par"
            onInput={(e) => updateInput('cableWidth', (e.target as HTMLInputElement).value.trim())}
            label={`4. ${t('damage_report_form.step_one.wire_size')}`}
          />
        </TeliaCol>
      </TeliaRow>

      <TeliaHeading variant="subsection-100" tag="h3">
        {`* 5. ${t('damage_report_form.step_one.wiring_instructions')}?`}
      </TeliaHeading>
      <div className="step-one-form__radio-buttons-compliance">
        {Object.entries(GuideCorrectOptions).map(([key, value]) => (
          <TeliaRadioButton
            key={key}
            checked={stepOne.isGuideCorrect === key}
            onClick={() => updateInput('isGuideCorrect', key)}
          >
            {t(value)}
          </TeliaRadioButton>
        ))}
      </div>

      <TeliaRow>
        <TeliaCol width={12} widthMd={6}>
          <TeliaTextInput
            type="text"
            maxlength={MAX_TEXTAREA_LENGTH}
            placeholder="1234567890"
            label={`6. ${t('damage_report_form.step_one.management_control_case_number')}`}
            value={stepOne.managementControlCaseNumber}
            onInput={(e) =>
              updateInput(
                'managementControlCaseNumber',
                (e.target as HTMLInputElement).value.trim()
              )
            }
          />
        </TeliaCol>
      </TeliaRow>

      <TeliaRow class="step-one-form__date-toggle">
        <TeliaCol width={12} widthMd={4}>
          <TeliaDatePicker
            required
            dataTestid="date-picker"
            invalidErrorMessage={t('error_message.invalid_date_format')}
            requiredErrorMessage={t('error_message.required')}
            disabled={stepOne.dateOfIncidentDisabled}
            value={stepOne.dateOfIncident}
            max={currentDate}
            onVocaChange={(e) => updateInput('dateOfIncident', e.detail.value)}
            label={`7. ${t('damage_report_form.step_one.damage_date')}`}
          />
        </TeliaCol>
        <TeliaCol width={3} widthMd={4}>
          <TeliaToggle
            dataTestid="date-toggler"
            label={t('damage_report_form.step_one.do_not_know')}
            labelPosition="after"
            selected={stepOne.dateOfIncidentDisabled}
            onVocaChange={(e) => {
              updateInput('dateOfIncidentDisabled', e.detail.value);
              updateInput('dateOfIncident', currentDate);
            }}
          />
        </TeliaCol>
      </TeliaRow>

      <TeliaRow>
        <TeliaCol width={12} widthMd={4}>
          <TeliaDatePicker
            invalidErrorMessage={t('error_message.invalid_date_format')}
            min={currentDate}
            value={stepOne.dateOfRefill}
            onVocaChange={(e) => updateInput('dateOfRefill', e.detail.value)}
            label={`8. ${t('damage_report_form.step_one.replacement_date')}`}
          />
        </TeliaCol>
      </TeliaRow>
    </>
  );
}

export default GroundCable;
