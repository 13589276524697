// @ts-ignore
import riveWasmUrl from '@rive-app/canvas/rive.wasm';
import { Alignment, Fit, Layout, RuntimeLoader, useRive } from '@rive-app/react-canvas';
import { TeliaGrid } from '@teliads/components/react';

import 'components/custom/loading-screen/LoadingAnimation.scss';

RuntimeLoader.setWasmUrl(riveWasmUrl);

export default function LoadingAnimation() {
  const { RiveComponent } = useRive(
    {
      src: '/5th_element.riv',
      artboard: '3D-RSF',
      layout: new Layout({
        fit: Fit.Cover,
        alignment: Alignment.Center,
      }),
      autoplay: true,
    },
    { fitCanvasToArtboardHeight: true }
  );

  return (
    <TeliaGrid className="loading-animation">
      <RiveComponent className="loading-animation__component" />
    </TeliaGrid>
  );
}
