export const VALID_PHONE_NUMBER_PATTERN = '^[+]?[0-9]{6}[0-9]+$';
// prettier-ignore
export const VALID_EMAIL_PATTERN = '^\\S+@\\S+\\.\\S+$'; // eslint-disable-line

const PHONE_NUMBER_REGEX = new RegExp(VALID_PHONE_NUMBER_PATTERN);
const EMAIL_REGEX = new RegExp(VALID_EMAIL_PATTERN);

export function validatePhoneNumber(phoneNumber: string): boolean {
  return !!phoneNumber.trim().length && PHONE_NUMBER_REGEX.test(phoneNumber);
}

export function validateEmailAddress(email: string): boolean {
  return !!email.trim().length && EMAIL_REGEX.test(email);
}
