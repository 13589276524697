import {
  TeliaButton,
  TeliaCol,
  TeliaDivider,
  TeliaForm,
  TeliaHeading,
  TeliaIcon,
  TeliaRow,
  TeliaTextInput,
} from '@teliads/components/react';
import { useTranslation } from 'react-i18next';

import './StepThreeForm.scss';
import { VALID_EMAIL_PATTERN, VALID_PHONE_NUMBER_PATTERN } from '../form-navigation/validations';

import Card from 'components/custom/card/Card';
import Paragraph from 'components/voca/paragraph/Paragraph';
import { useDamageReportForm } from 'contexts/damage-report-form.context';

export default function StepThreeForm() {
  const { t } = useTranslation();
  const { stepThree, setStepThree } = useDamageReportForm();
  const emailPlaceholder = 'example@mail.com';

  function updateInput(
    section: keyof typeof stepThree,
    which: keyof typeof stepThree.author,
    value: string
  ) {
    setStepThree((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [which]: value,
      },
    }));
  }

  function toggleIsSameAsAuthorBlock() {
    setStepThree((prev) => ({
      ...prev,
      onSite: {
        ...prev.onSite,
        isSameAsAuthor: !prev.onSite.isSameAsAuthor,
      },
    }));
  }

  return (
    <Card dataTestid="step-three-form-card">
      <TeliaForm name="step-three-form" className="step-three-form">
        <TeliaRow>
          <TeliaCol width={12} widthLg={8} className="step-three-form__main-info">
            <TeliaHeading tag="h1" variant="title-200" className="step-three-form__title">
              {t('damage_report_form.step_three.title')}
            </TeliaHeading>
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12}>
            <Paragraph variant="overline-100">
              {t('damage_report_form.step_three.reporter')}
            </Paragraph>
            <TeliaDivider className="step-three-form__divider" />
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12} widthLg={10}>
            <TeliaRow>
              <TeliaCol width={12} widthLg={5}>
                <TeliaTextInput
                  type="text"
                  label={t('damage_report_form.step_three.first_name')}
                  value={stepThree.author.firstName}
                  required
                  requiredErrorMessage={t('error_message.required')}
                  onInput={(e) =>
                    updateInput('author', 'firstName', (e.target as HTMLInputElement).value)
                  }
                />
              </TeliaCol>

              <TeliaCol width={12} widthLg={5}>
                <TeliaTextInput
                  type="text"
                  label={t('damage_report_form.step_three.last_name')}
                  value={stepThree.author.lastName}
                  required
                  requiredErrorMessage={t('error_message.required')}
                  onInput={(e) =>
                    updateInput('author', 'lastName', (e.target as HTMLInputElement).value)
                  }
                />
              </TeliaCol>
            </TeliaRow>
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12} widthLg={6}>
            <TeliaTextInput
              type="text"
              label={t('damage_report_form.step_three.company_name')}
              value={stepThree.author.organization}
              onInput={(e) =>
                updateInput('author', 'organization', (e.target as HTMLInputElement).value)
              }
            />
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12} widthLg={6} className="step-three-form__input-wrapper">
            <TeliaTextInput
              type="tel"
              label={t('damage_report_form.step_three.telephone')}
              value={stepThree.author.phone}
              required
              requiredErrorMessage={t('error_message.required')}
              pattern={VALID_PHONE_NUMBER_PATTERN}
              patternErrorMessage={t('error_message.incorrect_phone_number')}
              onInput={(e) => updateInput('author', 'phone', (e.target as HTMLInputElement).value)}
            />
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12} widthLg={6}>
            <TeliaTextInput
              type="email"
              label={t('damage_report_form.step_three.email_address')}
              value={stepThree.author.email}
              required
              requiredErrorMessage={t('error_message.required')}
              pattern={VALID_EMAIL_PATTERN}
              patternErrorMessage={t('error_message.incorrect_email_address')}
              placeholder={emailPlaceholder}
              onInput={(e) => updateInput('author', 'email', (e.target as HTMLInputElement).value)}
            />
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12}>
            <TeliaButton
              variant="text"
              size="lg"
              type="button"
              className="step-three-form__button"
              onClick={toggleIsSameAsAuthorBlock}
            >
              <TeliaIcon
                slot="left"
                size="sm"
                name={stepThree.onSite.isSameAsAuthor ? 'plus' : 'minus'}
              />
              {stepThree.onSite.isSameAsAuthor
                ? t('damage_report_form.step_three.same_as_notifier')
                : t('damage_report_form.step_three.remove_contact')}
            </TeliaButton>
          </TeliaCol>
        </TeliaRow>
      </TeliaForm>

      {!stepThree.onSite.isSameAsAuthor && (
        <TeliaForm name="step-three-form-reporter">
          <TeliaRow>
            <TeliaCol width={12}>
              <Paragraph variant="overline-100">
                {t('damage_report_form.step_three.instructors_on_site')}
              </Paragraph>
              <TeliaDivider className="step-three-form__divider" />
            </TeliaCol>
          </TeliaRow>

          <TeliaRow>
            <TeliaCol width={12} widthLg={10}>
              <TeliaRow>
                <TeliaCol width={12} widthLg={5}>
                  <TeliaTextInput
                    type="text"
                    label={t('damage_report_form.step_three.first_name')}
                    value={stepThree.onSite.firstName}
                    required
                    requiredErrorMessage={t('error_message.required')}
                    onInput={(e) =>
                      updateInput('onSite', 'firstName', (e.target as HTMLInputElement).value)
                    }
                  />
                </TeliaCol>

                <TeliaCol width={12} widthLg={5}>
                  <TeliaTextInput
                    type="text"
                    label={t('damage_report_form.step_three.last_name')}
                    value={stepThree.onSite.lastName}
                    required
                    requiredErrorMessage={t('error_message.required')}
                    onInput={(e) =>
                      updateInput('onSite', 'lastName', (e.target as HTMLInputElement).value)
                    }
                  />
                </TeliaCol>
              </TeliaRow>
            </TeliaCol>
          </TeliaRow>

          <TeliaRow>
            <TeliaCol width={12} widthLg={6}>
              <TeliaTextInput
                type="text"
                label={t('damage_report_form.step_three.company_name')}
                value={stepThree.onSite.organization}
                onInput={(e) =>
                  updateInput('onSite', 'organization', (e.target as HTMLInputElement).value)
                }
              />
            </TeliaCol>
          </TeliaRow>

          <TeliaRow>
            <TeliaCol width={12} widthLg={6} className="step-three-form__input-wrapper">
              <TeliaTextInput
                type="tel"
                label={t('damage_report_form.step_three.telephone')}
                value={stepThree.onSite.phone}
                required
                requiredErrorMessage={t('error_message.required')}
                pattern={VALID_PHONE_NUMBER_PATTERN}
                patternErrorMessage={t('error_message.incorrect_phone_number')}
                onInput={(e) =>
                  updateInput('onSite', 'phone', (e.target as HTMLInputElement).value)
                }
              />
            </TeliaCol>
          </TeliaRow>

          <TeliaRow>
            <TeliaCol width={12} widthLg={6}>
              <TeliaTextInput
                type="email"
                label={t('damage_report_form.step_three.email_address')}
                value={stepThree.onSite.email}
                required
                requiredErrorMessage={t('error_message.required')}
                pattern={VALID_EMAIL_PATTERN}
                patternErrorMessage={t('error_message.incorrect_email_address')}
                placeholder={emailPlaceholder}
                onInput={(e) =>
                  updateInput('onSite', 'email', (e.target as HTMLInputElement).value)
                }
              />
            </TeliaCol>
          </TeliaRow>
        </TeliaForm>
      )}
    </Card>
  );
}
