import {
  TeliaCol,
  TeliaDivider,
  TeliaIcon,
  TeliaLink,
  TeliaRow,
  TeliaStyleLink,
} from '@teliads/components/react';
import { useTranslation } from 'react-i18next';

import SummaryListItem, { SummaryListItemProps } from './SummaryListItem';
import './StepSummary.scss';

import Paragraph from 'components/voca/paragraph/Paragraph';
import { useDamageReportForm } from 'contexts/damage-report-form.context';

type SummaryListProps = {
  items: (SummaryListItemProps & { isRendered: boolean })[];
  title: string;
  redirectToStep: number;
  dataTestid?: string;
};

export default function SummaryList({
  items,
  title,
  redirectToStep,
  dataTestid = '',
}: SummaryListProps) {
  const { t } = useTranslation();
  const { setActiveStep } = useDamageReportForm();

  return (
    <>
      <TeliaRow>
        <TeliaCol width={12}>
          <div className="summary-section-block">
            <Paragraph variant="overline-100">{title}</Paragraph>

            <TeliaStyleLink variant="text">
              <TeliaLink onClick={() => setActiveStep(redirectToStep)}>
                {`${t('damage_report_form.step_summary.go_to')} ${title.toLowerCase()}`}
                <TeliaIcon slot="right" size="sm" name="arrow-right" />
              </TeliaLink>
            </TeliaStyleLink>
          </div>

          <TeliaDivider className="summary-section-block__divider" />
        </TeliaCol>
      </TeliaRow>

      <ul data-testid={dataTestid} className="summary-list">
        {items.map(
          (item) =>
            item.isRendered && (
              <SummaryListItem
                key={item.leftSideText}
                leftSideText={item.leftSideText}
                rightSideText={item.rightSideText}
              />
            )
        )}
      </ul>
    </>
  );
}
