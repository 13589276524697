import { FaqSectionFragment } from '../../graphql/__generated/sdk';
import { generateSeoMetadata } from '../../utils/transformers';

import { FAQItem, FAQPageProps, FAQSection, Level2FaqCtfData } from './models';

export function buildFAQItem(faqItem: FAQItem): FAQItem {
  return {
    question: faqItem.question,
    answer: faqItem.answer,
  };
}

export function buildFAQSection(faqSection: FaqSectionFragment): FAQSection {
  return {
    items: faqSection.faqSectionCollection.items.map(buildFAQItem),
    title: faqSection.sectionTitle,
  };
}

export default function getMappedPageContent(data: Level2FaqCtfData): FAQPageProps {
  const item = data[0].data.items[0];

  return {
    heroBanner: {
      title: item.heroBanner.title,
      preamble: item.heroBanner.richPreamble?.json || '',
      imageUrl: item.heroBanner.primaryMediaImage.image.url || '',
      videoUrl: undefined,
    },
    seoMetadata: generateSeoMetadata(item.seoMetadata),
    sections: item.faqSectionsCollection.items.map(buildFAQSection),
  };
}
