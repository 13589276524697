import { TeliaIcon } from '@teliads/components/react';

import './CarouselButton.scss';

interface Props {
  variant: 'left' | 'right';
}

export default function CarouselButton({ variant }: Props) {
  return (
    <button
      type="button"
      className="carousel-button"
      data-testid={`button-${variant}`}
      data-glide-dir={variant === 'left' ? '<' : '>'}
    >
      <TeliaIcon name={`arrow-${variant}`} size="md" />
    </button>
  );
}
