import { GoogleMap, Libraries, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';

import { GoogleLatLngLiteral, Place } from '../types';
import './MapWithSearch.scss';

import PlacesAutocomplete from './places-autocomplete/PlacesAutocomplete';

import { useDamageReportForm } from 'contexts/damage-report-form.context';

const googleMapsLibraries = ['places', 'geocoding'] as Libraries;

export default function MapWithSearch() {
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '24px',
  };

  const DEFAULT_LOCATION_STOCKHOLM = {
    lat: 59.334591,
    lng: 18.06324,
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: googleMapsLibraries,
  });
  const { stepTwo, setStepTwo } = useDamageReportForm();

  const [isLocationAccessGranted, setIsLocationAccessGranted] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [userLocation, setUserLocation] = useState<GoogleLatLngLiteral>(DEFAULT_LOCATION_STOCKHOLM);
  const [markerPosition, setMarkerPosition] = useState<GoogleLatLngLiteral | null>();
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);

  const updateFormData = useCallback(
    ({ location, address }: Place) => {
      setStepTwo((prev) => ({
        ...prev,
        position: {
          latitude: location.lat,
          longitude: location.lng,
        },
        address,
      }));
    },
    [setStepTwo]
  );

  const loadUserLocation = useCallback(() => {
    if (
      stepTwo.address &&
      typeof stepTwo.position.longitude !== 'undefined' &&
      typeof stepTwo.position.latitude !== 'undefined'
    ) {
      setUserLocation({
        lat: stepTwo.position.latitude,
        lng: stepTwo.position.longitude,
      });
    } else if (navigator.geolocation) {
      const successCallback = ({ coords }: GeolocationPosition) => {
        setUserLocation({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      };
      const errorCallback = (error: GeolocationPositionError) => {
        console.error('Error getting user location:', error);
        setUserLocation(DEFAULT_LOCATION_STOCKHOLM);
      };

      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.error('Geolocation is not supported by this browser.');
      setUserLocation(DEFAULT_LOCATION_STOCKHOLM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.geolocation, stepTwo]);

  const loadMap = useCallback(
    (mapInstance: google.maps.Map) => {
      setMap(mapInstance);
      setGeocoder(new window.google.maps.Geocoder());
      loadUserLocation();
    },
    [loadUserLocation]
  );

  const resetMap = useCallback(() => {
    setMap(null);
  }, [setMap]);

  const setLocationByGeocoder = useCallback(
    (location: GoogleLatLngLiteral, forceShowAddress = false) => {
      if (geocoder) {
        geocoder.geocode({ location }, (results, status) => {
          if (status === 'OK') {
            const address =
              (isLocationAccessGranted || forceShowAddress) && results && results[0]
                ? results[0].formatted_address
                : '';
            updateFormData({
              location: {
                lat: location.lat,
                lng: location.lng,
              },
              address,
            });
          } else {
            console.error('Geocoder failed due to:', status);
          }
        });
      }
    },
    [geocoder, isLocationAccessGranted, updateFormData]
  );

  const setMapPosition = useCallback(
    (place: Place) => {
      if (map) {
        map.panTo(place.location);
        setMarkerPosition(place.location);
        updateFormData(place);
      }
    },
    [map, updateFormData]
  );

  const setMapMarker = useCallback(
    (event: google.maps.MapMouseEvent) => {
      setMarkerPosition(event.latLng as GoogleLatLngLiteral | null);

      if (event.latLng) {
        try {
          const latitudeAndLongitude = event.latLng.toJSON();
          setLocationByGeocoder(latitudeAndLongitude, true);
        } catch (error) {
          console.error('Error fetching address:', error);
        }
      }
    },
    [setMarkerPosition, setLocationByGeocoder]
  );

  useEffect(() => {
    if (map) {
      map.panTo(userLocation);
      setMarkerPosition(userLocation);
      setLocationByGeocoder(userLocation);
    }
  }, [userLocation, map, setLocationByGeocoder]);

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      if (permissionStatus.state === 'prompt') {
        permissionStatus.onchange = () => {
          setIsLocationAccessGranted(permissionStatus.state === 'granted');
        };
      } else {
        setIsLocationAccessGranted(permissionStatus.state === 'granted');
      }
    });
  }, [isLocationAccessGranted, userLocation]);

  return isLoaded ? (
    <div className="map-container">
      <div className="map-container__places-container">
        <PlacesAutocomplete onPlaceChanged={setMapPosition} />
      </div>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={userLocation}
        zoom={15}
        onLoad={loadMap}
        onUnmount={resetMap}
        onClick={setMapMarker}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </div>
  ) : (
    <p className="status-message">{loadError ? 'Error loading map' : 'Loading map...'}</p>
  );
}
