import PageContainer from '../../components/custom/page-container/PageContainer';
import {
  GetAssemblyLevel2FaqCollectionDocument,
  GetAssemblyLevel2FaqCollectionQuery,
} from '../../graphql/__generated/sdk';
import useI18nQuery from '../../utils/hooks/useI18nQuery';

import FAQPageTemplate from './FAQPageTemplate';
import getMappedPageContent from './mappers';
import { FAQPageProps, Level2FaqCtfData } from './models';

import useRedirectTo404 from 'utils/hooks/useRedirectTo404';

export default function FAQPage() {
  const query = useI18nQuery<GetAssemblyLevel2FaqCollectionQuery>(
    GetAssemblyLevel2FaqCollectionDocument,
    {
      variables: {
        slug: 'vanliga-fragor',
      },
    }
  );

  useRedirectTo404(query.data?.data.items[0], query.loading);

  return (
    <PageContainer<Level2FaqCtfData, FAQPageProps>
      queryList={[query]}
      mapperFn={getMappedPageContent}
    >
      {(result) => (
        <FAQPageTemplate
          heroBanner={result.heroBanner}
          seoMetadata={result.seoMetadata}
          sections={result.sections}
        />
      )}
    </PageContainer>
  );
}
