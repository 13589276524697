import { TeliaIcon } from '@teliads/components/react';
import { CSSProperties, ReactNode, useRef, useState } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';

import { transitionTimingFunction } from 'utils/constants';

import './ExpansionPanel.scss';

interface Props {
  title: string;
  children: ReactNode;
  closed?: () => void;
  opened?: () => void;
}

type TransitionStyles = {
  [key in TransitionStatus]?: Partial<CSSProperties>;
};

export default function ExpansionPanel({ title, children, opened, closed }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const togglePanel = () => {
    const newIsExpandedValue = !isExpanded;

    setIsExpanded(newIsExpandedValue);

    if (newIsExpandedValue) {
      opened && opened();
    } else {
      closed && closed();
    }
  };

  const getTransitionStyles = (state: TransitionStatus): CSSProperties | undefined => {
    if (!contentRef.current) {
      return undefined;
    }

    const height = contentRef.current!.scrollHeight;

    const entered = {
      maxHeight: `${height}px`,
      transitionDuration: `${getDynamicHeightDurationForCubicBezierAnimation(height)}ms`,
      transitionTimingFunction,
    };

    const transitionStyles: TransitionStyles = {
      entering: { maxHeight: '0' },
      entered,
      exiting: entered,
      exited: {
        ...entered,
        maxHeight: '0',
      },
    };

    return transitionStyles[state];
  };

  /**
   * The height of the expansion-panel__content is dynamic, so the duration of the animation should also be dynamic
   * and fit the transition function curve in order to not have slow / lag effect
   */
  const getDynamicHeightDurationForCubicBezierAnimation = (height: number): number => {
    if (!height) {
      return 0;
    }

    const constant = height / 36;
    return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
  };

  return (
    <div className={`expansion-panel expansion-panel--${isExpanded ? 'expanded' : 'collapsed'}`}>
      <button className="expansion-panel__header" aria-expanded={isExpanded} onClick={togglePanel}>
        <span>{title}</span>
        <TeliaIcon className="expansion-panel__expand-icon" name="chevron-down" size="sm" />
      </button>

      <Transition nodeRef={contentRef} in={isExpanded} timeout={50}>
        {(state: TransitionStatus) => (
          <div
            ref={contentRef}
            className={`expansion-panel__content ${
              isExpanded ? 'expansion-panel__content--visible' : 'expansion-panel__content--hidden'
            }`}
            aria-hidden={!isExpanded}
            style={{ ...getTransitionStyles(state) }}
            data-testid="expansion-panel-content"
          >
            {children}
          </div>
        )}
      </Transition>
    </div>
  );
}
