// @ts-nocheck
import { DataEntry, PageLevel0Props } from './models';

import {
  generateHeroBanner,
  generateSeoMetadata,
  getFirstContentItem,
  prepareBaseCards,
} from 'utils/transformers';

export function getMappedPageContent(data: DataEntry): PageLevel0Props {
  const [pageData] = data;
  const content = pageData.data.items[0];

  return {
    heroBanner: generateHeroBanner({
      title: content.heroBanner.title,
      preamble: content.heroBanner.richPreamble,
      image: content.heroBanner.primaryMediaImage,
      videoUrl: content.heroBanner.primaryMediaVideo?.video?.url,
      cta: getFirstContentItem(content.heroBanner.callToActionCollection),
    }),
    topCardsDescription: {
      title: content.topCardsDescription.title,
      preamble: content.topCardsDescription.richPreamble,
      cta: getFirstContentItem(content.topCardsDescription.callToActionCollection),
      imageUrl: content.topCardsDescription.primaryMediaImage?.image.url,
    },
    topCards: prepareBaseCards(content.topCardItemsCollection),
    infoBox: {
      title: content.infoBox.title,
      preamble: content.infoBox.richPreamble,
      cta: getFirstContentItem(content.infoBox.callToActionCollection),
    },
    bottomCards: {
      title: content.bottomCardsHeading,
      items: prepareBaseCards(content.bottomCardItemsCollection),
    },
    seoMetadata: generateSeoMetadata(content.seoMetadata),
  };
}
