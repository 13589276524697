import { TeliaButton, TeliaIcon } from '@teliads/components/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useBeforeUnload } from 'react-router-dom';
import './FormNavigation.scss';

import { FormNavigationHandlersWithError } from '../types';

import Paragraph from 'components/voca/paragraph/Paragraph';
import { useDamageReportForm } from 'contexts/damage-report-form.context';

export type FormNavigationProps = {
  isExpanded: boolean;
} & Omit<FormNavigationHandlersWithError, 'handleReset'>;

export default function FormNavigation({
  isExpanded,
  handleBack,
  handleNext,
  handleExpansion,
  isError,
}: FormNavigationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeStep, steps, validateFormStep, isSendingRequest } = useDamageReportForm();

  const [isAllowedToForwardStep, setIsAllowedToForwardStep] = useState(false);

  const isLastStep = steps.length === activeStep;
  const isSubmitFormAllowed = isAllowedToForwardStep && isLastStep;

  function cancelForm() {
    if (window.confirm(t('confirm_close'))) {
      navigate('/anmal-skada');
    }
  }

  useBeforeUnload((e) => {
    e.preventDefault();
    e.returnValue = '';
  });

  useEffect(() => {
    setIsAllowedToForwardStep(validateFormStep(activeStep));
  }, [validateFormStep, activeStep]);

  return (
    <div className="form-navigation">
      <TeliaButton
        dataTestid="back-button"
        allyLabel="Go back in form step"
        variant="secondary"
        size="md"
        type="button"
        className="step-button"
        disabled={activeStep === 1}
        onClick={handleBack}
      >
        {t('action.previous')}
      </TeliaButton>
      <TeliaButton
        dataTestid="next-button"
        allyLabel={`${isSubmitFormAllowed ? 'Submit the form' : 'Go forward in form step'}`}
        variant={`${isSubmitFormAllowed ? 'expressive' : 'primary'}`}
        size="md"
        type={`${isSubmitFormAllowed ? 'submit' : 'button'}`}
        className="step-button"
        disabled={!isAllowedToForwardStep || isSendingRequest}
        onClick={handleNext}
      >
        <span>{isSubmitFormAllowed ? t('action.finish') : t('action.next')}</span>
      </TeliaButton>

      <TeliaButton
        dataTestid="close-button"
        allyLabel="Close and cancel whole form"
        variant="text"
        size="md"
        type="button"
        className="step-button step-button--close"
        onClick={cancelForm}
      >
        {t('action.close')}
        <TeliaIcon slot="right" className="step-button__icon" name="close" size="sm" />
      </TeliaButton>
      <TeliaButton
        dataTestid="expand-button"
        allyLabel="Expand and collapse file upload"
        variant="text"
        size="md"
        type="button"
        className="step-button step-button--expand"
        onClick={handleExpansion}
      >
        <span className="step-button__text">
          {isExpanded ? t('action.collapse') : t('action.expand')}
          <TeliaIcon
            className="step-button__icon"
            name={isExpanded ? 'chevron-down' : 'chevron-up'}
            size="sm"
          />
        </span>
      </TeliaButton>

      {isError && (
        <Paragraph className="form-navigation__error">{t('error_message.fetch_error')}</Paragraph>
      )}
    </div>
  );
}
