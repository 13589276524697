import { PropsWithChildren } from 'react';
import './ChipChoice.scss';

interface ChipChoiceProps {
  checked: boolean;
  onClick: () => void;
  dataTestid: string;
}

const ChipChoice = ({
  checked,
  onClick,
  children,
  dataTestid,
}: PropsWithChildren<ChipChoiceProps>) => {
  return (
    <button
      className={`chip-choice ${checked ? 'chip-choice--selected' : ''}`}
      onClick={onClick}
      data-testid={dataTestid}
      type="button"
    >
      {children}
    </button>
  );
};

export default ChipChoice;
