import Paragraph from 'components/voca/paragraph/Paragraph';
import './StepSummary.scss';

export type SummaryListItemProps = {
  leftSideText: string;
  rightSideText?: string | Date;
};

export default function SummaryListItem({ leftSideText, rightSideText }: SummaryListItemProps) {
  function buildRightSideText() {
    if (rightSideText instanceof Date) {
      return rightSideText.toLocaleDateString('sv');
    }

    return rightSideText;
  }

  return (
    <li className="summary-list-item">
      <Paragraph className="summary-list-item__paragraph">
        <strong>{leftSideText}</strong>
      </Paragraph>
      <Paragraph className="summary-list-item__paragraph">{buildRightSideText()}</Paragraph>
    </li>
  );
}
