import {
  DisplayVariantType,
  TitleVariantType,
} from '@teliads/components/dist/types/types/HeadingVariant';
import { HTagType } from '@teliads/components/dist/types/types/HTag';
import { TeliaHeading } from '@teliads/components/react';
import { ReactNode } from 'react';

import DisableHyphenation from '../../custom/disable-hyphenation/DisableHyphenation';

type HeadingProps = {
  tag?: HTagType;
  variant?: TitleVariantType | DisplayVariantType;
  className?: string;
  children: ReactNode;
  dataTestId?: string;
};

const Heading = ({ variant, tag, className, children, dataTestId }: HeadingProps) => {
  return (
    <TeliaHeading variant={variant} tag={tag} className={className} data-testid={dataTestId}>
      <DisableHyphenation>{children}</DisableHyphenation>
    </TeliaHeading>
  );
};

export default Heading;
