import { TeliaCtaLink } from '@teliads/components/react';
import 'components/custom/base-card/BaseCard.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CardVariantEnum } from 'components/custom/base-card/types';
import Heading from 'components/voca/heading/Heading';
import Paragraph from 'components/voca/paragraph/Paragraph';
import { ButtonVariantEnum } from 'utils/constants';
import { Dimensions, createMultipleSizeImages } from 'utils/utils';

interface Props {
  cardVariant: CardVariantEnum;
  buttonVariant?: ButtonVariantEnum;
  title: string;
  text?: string;
  buttonText?: string;
  imgSrc: string;
  link: string;
  focusArea?: string;
  altText?: string;
  dataTestid?: string;
}

export const BaseCard: React.FC<Props> = ({
  cardVariant = CardVariantEnum.REGULAR,
  buttonVariant = ButtonVariantEnum.SECONDARY_WHITE,
  title,
  text = '',
  buttonText,
  imgSrc,
  link,
  focusArea = 'center',
  altText = '',
  dataTestid,
}) => {
  const headingVariant = [
    CardVariantEnum.MEDIUM,
    CardVariantEnum.REGULAR,
    CardVariantEnum.SECONDARY_REGULAR,
  ].includes(cardVariant)
    ? 'title-300'
    : 'title-500';

  const { t } = useTranslation();

  const ctaText = buttonText ? buttonText : t('read_more');

  const image = useMemo(() => {
    function getImageDimensions(): Dimensions {
      switch (cardVariant) {
        case CardVariantEnum.REGULAR: {
          return {
            small: ['3:4', 750],
            medium: ['5:4', 400],
            large: ['5:4', 500],
          };
        }

        case CardVariantEnum.SECONDARY_REGULAR: {
          return {
            small: ['4:3', 750],
            medium: ['4:3', 850],
            large: ['4:5', 950],
          };
        }

        case CardVariantEnum.MEDIUM: {
          return {
            small: ['5:4', 450],
            medium: ['3:2', 650],
            large: ['3:2', 900],
          };
        }

        case CardVariantEnum.LARGE: {
          return {
            small: ['16:9', 450],
            medium: ['16:9', 650],
            large: ['16:9', 950],
          };
        }

        case CardVariantEnum.SQUARE: {
          return {
            small: ['4:3', 750],
            medium: ['1:1', 500],
            large: ['1:1', 700],
          };
        }
      }
    }

    return createMultipleSizeImages(
      {
        url: imgSrc,
        altText,
        focusArea,
      },
      getImageDimensions()
    );
  }, [imgSrc, altText, focusArea, cardVariant]);

  return (
    <div
      className={`base-card base-card--${cardVariant}`}
      data-testid={dataTestid ? dataTestid : 'base-card'}
    >
      <div className="base-card__wrapper">
        <section className={`base-card__section base-card__section--${cardVariant}`}>
          <Heading
            tag="h3"
            variant={headingVariant}
            className={`base-card__title ${text && 'base-card__title--bottom-gap'}`}
          >
            {title}
          </Heading>

          {text && (
            <Paragraph variant="paragraph-100" className="base-card__text">
              {text}
            </Paragraph>
          )}
        </section>

        <TeliaCtaLink
          variant={buttonVariant}
          className="base-card__button"
          //@ts-ignore
          rightIcon={{
            name: 'arrow-right',
            size: 'md',
            allyTitle: 'Call to action',
          }}
          size="md"
          dataTestid="base-card-button"
          href={link}
        >
          {ctaText}
        </TeliaCtaLink>
      </div>
      {imgSrc && (
        <picture>
          <source data-testid="small-image-source" media="(max-width:599px)" srcSet={image.small} />
          <source
            data-testid="medium-image-source"
            media="(max-width:1023px)"
            srcSet={image.medium}
          />
          <img
            data-testid="default-image"
            src={image.large}
            alt={altText}
            className="base-card__image"
          />
        </picture>
      )}
    </div>
  );
};
