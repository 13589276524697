export enum DamageCategoryOptions {
  GROUND_CABLE = 'damage_report_form.step_one.category_ground',
  AIR_CABLE = 'damage_report_form.step_one.category_air',
  CABINET = 'damage_report_form.step_one.category_cabinet',
  TECH_BUILDING = 'damage_report_form.step_one.category_tech_building',
  POLE = 'damage_report_form.step_one.category_post',
  WELL = 'damage_report_form.step_one.category_well',
}

export enum GuideCorrectOptions {
  YES = 'damage_report_form.step_one.yes',
  NO = 'damage_report_form.step_one.no',
  UNKNOWN = 'damage_report_form.step_one.do_not_know',
}

export enum FiberOptions {
  FIBER_IN_SEWER = 'damage_report_form.step_one.fiber_in_sewer',
  SEWER_ONLY = 'damage_report_form.step_one.sewer_only',
}

export enum AirCableCopperOptions {
  PLASTIC_SHEATHED = 'damage_report_form.step_one.plastic_sheathed',
  ABOVE_CHANNELING = 'damage_report_form.step_one.above_channeling',
}

export enum CopperOptions {
  PLASTIC_SHEATHED = 'damage_report_form.step_one.plastic_sheathed',
  LEAD_SHEATHED = 'damage_report_form.step_one.lead_sheathed',
  ABOVE_CHANNELING = 'damage_report_form.step_one.above_channeling',
}

export enum DamageTypes {
  BROKEN_WIRE = 'damage_report_form.step_one.broken_wire',
  LOW_HANGING_WIRE = 'damage_report_form.step_one.low_hanging_wire',
  TREE_ON_WIRE = 'damage_report_form.step_one.tree_on_wire',
  MISCELLANEOUS = 'damage_report_form.step_one.miscellaneous',
}

export enum PoleDamageTypes {
  BROKEN_POLE = 'damage_report_form.step_one.broken_pole',
  LEANING_POLE = 'damage_report_form.step_one.leaning_pole',
  MISCELLANEOUS = 'damage_report_form.step_one.miscellaneous',
}

export enum PoleCopperOptions {
  FIBER = 'damage_report_form.step_one.pole_fiber',
  COOPER = 'damage_report_form.step_one.pole_copper',
  LREAD_IN_SEWERAGE = 'damage_report_form.step_one.pole_lead_in_sewerage',
  UNKNOWN = 'damage_report_form.step_one.pole_unknown',
}

export enum CabinetTypes {
  FIBER_CABINET = 'damage_report_form.step_one.cabinet_fiber',
  COOPER_CABINET = 'damage_report_form.step_one.cabinet_copper',
  UNKNOWN_CABINET = 'damage_report_form.step_one.cabinet_unknown',
}

export enum CabinetDamageTypes {
  CABINET_OPEN = 'damage_report_form.step_one.cabinet_open',
  CABINET_CRASHED = 'damage_report_form.step_one.cabinet_crashed',
  CABINET_MISSING_HATCH = 'damage_report_form.step_one.cabinet_missing_hatch',
  CABINET_LEANING = 'damage_report_form.step_one.cabinet_leaning',
  CABINET_MISC = 'damage_report_form.step_one.cabinet_miscellaneous',
}
