import { SEOProps } from 'components/custom/SEO/SEO';
import { ContentfulRichText, HeroBannerContent } from 'utils/models';

export enum ContentfulTypename {
  ARTICLE = 'Article',
  ARTICLE_ASSEMBLY = 'ArticleAssembly',
  ASSEMBLY_LEVEL_0 = 'AssemblyLevel0',
  ASSEMBLY_LEVEL_1 = 'AssemblyLevel1',
  ASSEMBLY_LEVEL_2 = 'AssemblyLevel2',
  ASSEMBLY_LEVEL_2_FAQ = 'AssemblyLevel2Faq',
  NEWSROOM_ASSEMBLY = 'NewsroomAssembly',
  ASSEMBLY_REPORTS_AND_PRESENTATIONS = 'AssemblyReportsAndPresentations',
  PERSON = 'Person',
  TEASER = 'Teaser',
}

export type SectionCard = Record<string, any>;

type Section = {
  internalName: string;
  description?: ContentfulRichText;
  contentCollection: {
    items: SectionCard[];
  };
};

export interface CollectionProps {
  heroBanner: HeroBannerContent;
  description?: ContentfulRichText;
  sections: Section[];
  seoMetadata: SEOProps | null;
}
