import {
  TeliaBannerBillboard,
  TeliaCol,
  TeliaCtaLink,
  TeliaGrid,
  TeliaHeroBanner,
  TeliaRow,
  TeliaTextSpacing,
  TeliaVideoPlayer,
} from '@teliads/components/react';
import { ReactNode } from 'react';

import { CtaContent } from '../../../utils/models';
import Heading from '../heading/Heading';
import Paragraph from '../paragraph/Paragraph';
import './HeroBanner.scss';

interface Video {
  url: string | undefined;
  poster: string | undefined;
}

interface Props {
  title: string;
  preamble?: string | ReactNode;
  video?: Video | undefined;
  cta?: CtaContent;
  billboardEnabled?: boolean;
}

export default function HeroBanner({ title, preamble, video, cta, billboardEnabled }: Props) {
  function renderBannerBody() {
    return (
      <TeliaTextSpacing>
        <Heading tag="h1" variant="display-200" className="hero-banner__title">
          {title}
        </Heading>

        {preamble && (
          <Paragraph
            className="hero-banner__preamble"
            variant={!billboardEnabled ? 'preamble-200' : 'preamble-100'}
          >
            {preamble}
          </Paragraph>
        )}

        {cta && (
          <TeliaCtaLink
            href={cta.url}
            size="md"
            variant="expressive"
            dataTestid="hero-banner-cta-link"
          >
            {cta.text}
          </TeliaCtaLink>
        )}
      </TeliaTextSpacing>
    );
  }

  const wrapperVideoClass = billboardEnabled
    ? 'hero-banner-wrapper--with-video-billboard'
    : 'hero-banner-wrapper--with-video';

  return (
    <article
      className={`hero-banner-wrapper ${video ? wrapperVideoClass : ''}`}
      data-testid="hero-banner-wrapper"
      key={title + video?.url}
    >
      {!video ? (
        <TeliaGrid>
          <TeliaRow>
            <TeliaCol width={12}>{renderBannerBody()}</TeliaCol>
          </TeliaRow>
        </TeliaGrid>
      ) : billboardEnabled ? (
        <TeliaBannerBillboard dark enableVideo className="hero-banner" imgSrc={''}>
          {renderBannerBody()}
          <TeliaVideoPlayer
            autoplay
            loop
            muted
            slot="video"
            type="html"
            src={video.url}
            poster={video.poster}
            className="hero-banner__video-player"
            dataTestid="hero-banner-video-player"
          />
        </TeliaBannerBillboard>
      ) : (
        <TeliaHeroBanner
          imgSrc=""
          backgroundImageFrom="lg"
          enableVideo
          className="hero-banner"
          backgroundColor="#1f002e"
        >
          {renderBannerBody()}
          <TeliaVideoPlayer
            autoplay
            loop
            muted
            slot="video"
            type="html"
            src={video.url}
            poster={video.poster}
            className="hero-banner__video-player"
            dataTestid="hero-banner-video-player"
          />
        </TeliaHeroBanner>
      )}
    </article>
  );
}
