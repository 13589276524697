import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { NavigationLink } from '../../../voca/navigation/navigation-stub/models';
import ExpansionPanel from '../../expansion-panel/ExpansionPanel';

interface Props {
  navigationLink: NavigationLink;
  parentNavigationLink: NavigationLink;
  onNavigationChange: () => void;
}

export default function MobileHeaderNestedLinks({
  navigationLink,
  parentNavigationLink,
  onNavigationChange,
}: Props) {
  const { t } = useTranslation();

  let navigationLinkContent;

  if (parentNavigationLink.name != '' && navigationLink.path === '') {
    navigationLinkContent = (
      <Link
        className="mobile-header-navigation__link"
        to={parentNavigationLink.directPath!}
        onClick={onNavigationChange}
      >
        {t('go_to')} {parentNavigationLink.name}
      </Link>
    );
  } else if (navigationLink.children) {
    const childParent = navigationLink.children[0];

    const expansionPanelContent = (
      <ul className="mobile-header-navigation__nested-list">
        {navigationLink.children.map((navigationLink: NavigationLink) => (
          <MobileHeaderNestedLinks
            key={navigationLink.id || childParent.path}
            navigationLink={navigationLink}
            parentNavigationLink={childParent}
            onNavigationChange={onNavigationChange}
          />
        ))}
      </ul>
    );

    navigationLinkContent =
      childParent.path && navigationLink.name ? (
        <ExpansionPanel title={navigationLink.name}>{expansionPanelContent}</ExpansionPanel>
      ) : (
        expansionPanelContent
      );
  } else if (navigationLink.directPath && navigationLink.name) {
    navigationLinkContent = (
      <Link
        className="mobile-header-navigation__link"
        to={navigationLink.directPath!}
        onClick={onNavigationChange}
      >
        {navigationLink.name}
      </Link>
    );
  }
  return navigationLinkContent ? (
    <li className="mobile-header-navigation__list-item">{navigationLinkContent}</li>
  ) : null;
}
