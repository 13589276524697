import { TeliaDivider } from '@teliads/components/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionStatus } from 'react-transition-group';

import { animationDurationMs } from '../../../../utils/constants';
import { NonEmptyArray } from '../../../../utils/models';
import { NavigationLink } from '../../../voca/navigation/navigation-stub/models';
import MobileHeaderLevel1Navigation from '../mobile-header-level1-navigation/MobileHeaderLevel1Navigation';
import MobileHeaderNestedLinks from '../mobile-header-nested-links/MobileHeaderNestedLinks';

import './MobileHeaderNavigation.scss';

interface Props {
  rootLevel: NavigationLink;
  selectedNavLink?: NavigationLink | null;
  onNavigationChange: () => void;
  onSelectedNavLink: (navigationLink: NavigationLink) => void;
  isBackToHome: boolean;
}

export default function MobileHeaderNavigation({
  rootLevel,
  selectedNavLink,
  onNavigationChange,
  onSelectedNavLink,
  isBackToHome,
}: Props) {
  const navContainerRef = useRef(null);
  const [isNavContainerTransitionOpen, setIsNavContainerTransitionOpen] =
    useState<boolean>(isBackToHome);

  const slideIn = (navigationLink: NavigationLink) => {
    setIsNavContainerTransitionOpen(true);
    onSelectedNavLink(navigationLink);
  };

  const isNavigationLinkShown = (navigationLinkLevel1: NavigationLink): boolean => {
    return selectedNavLink?.id === navigationLinkLevel1.id;
  };

  const isLastItem = (list: NonEmptyArray<NavigationLink>, index: number): boolean => {
    return index === list.length - 1;
  };

  useEffect(() => {
    setIsNavContainerTransitionOpen(isBackToHome);
  }, [isBackToHome]);

  return (
    <div className="mobile-header-navigation" data-testid="mobile-header-navigation">
      <div className="mobile-header-navigation__body">
        <CSSTransition
          nodeRef={navContainerRef}
          in={isNavContainerTransitionOpen}
          timeout={animationDurationMs}
          classNames="slide"
        >
          {(state: TransitionStatus) => (
            <nav ref={navContainerRef} className="mobile-header-navigation__nav">
              {rootLevel.children && (
                <>
                  <ul
                    className={`mobile-header-navigation__level1-list ${
                      state === 'entered' ? 'mobile-header-navigation__level1-list--hidden' : ''
                    }`}
                    data-testid="mobile-header-navigation-level1-list"
                  >
                    {rootLevel.children.map((navigationLinkLevel1, index) => (
                      <Fragment key={navigationLinkLevel1.id}>
                        <MobileHeaderLevel1Navigation
                          navigationLink={navigationLinkLevel1}
                          onNavigationChange={onNavigationChange}
                          slideIn={slideIn}
                        />
                        {isLastItem(rootLevel.children!, index) && <TeliaDivider />}
                      </Fragment>
                    ))}
                  </ul>

                  {rootLevel.children
                    .filter((navigationLinkLevel1) => !!navigationLinkLevel1.children)
                    .map((navigationLinkLevel1) => (
                      <ul
                        key={navigationLinkLevel1.id}
                        data-testid={`mobile-header-navigation-level2-list`}
                        className={`mobile-header-navigation__level2-list ${
                          isNavigationLinkShown(navigationLinkLevel1)
                            ? 'mobile-header-navigation__level2-list--show'
                            : ''
                        }`}
                      >
                        {navigationLinkLevel1.children!.map((navigationLinkLevel2) => (
                          <MobileHeaderNestedLinks
                            key={navigationLinkLevel2.id}
                            navigationLink={navigationLinkLevel2}
                            parentNavigationLink={navigationLinkLevel1}
                            onNavigationChange={onNavigationChange}
                          />
                        ))}
                      </ul>
                    ))}
                </>
              )}
            </nav>
          )}
        </CSSTransition>
      </div>
    </div>
  );
}
