import './ReportWizard.scss';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ReportForm from './ReportForm';
import { CopperOptions, FiberOptions } from './step-one/models';

import Stepper from 'components/custom/stepper/Stepper';
import { useDamageReportForm } from 'contexts/damage-report-form.context';
import useHideChatBot from 'utils/use-hide-chatbot';

export default function ReportWizard() {
  const HOST = process.env.REACT_APP_HOST;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [skipped, setSkipped] = useState(new Set<number>());
  const [isError, setIsError] = useState(false);
  const {
    activeStep,
    setActiveStep,
    steps,
    validateFormStep,
    setIsSendingRequest,
    stepOne,
    stepTwo,
    stepThree,
    selectedFiles,
    sessionId,
  } = useDamageReportForm();

  function createFormData() {
    const formData = new FormData();
    const jsonIssueData = JSON.stringify({
      description: stepOne.description,
      contacts: {
        author: {
          name: `${stepThree.author.firstName} ${stepThree.author.lastName}`,
          email: stepThree.author.email,
          phone: stepThree.author.phone,
          organization: stepThree.author.organization,
        },
        onSite: {
          name: stepThree.onSite.isSameAsAuthor
            ? `${stepThree.author.firstName} ${stepThree.author.lastName}`
            : `${stepThree.onSite.firstName} ${stepThree.onSite.lastName}`,
          email: stepThree.onSite.isSameAsAuthor ? stepThree.author.email : stepThree.onSite.email,
          phone: stepThree.onSite.isSameAsAuthor ? stepThree.author.phone : stepThree.onSite.phone,
          organization: stepThree.onSite.isSameAsAuthor
            ? stepThree.author.organization
            : stepThree.onSite.organization,
        },
      },

      site: {
        description: stepTwo.description,
        location: {
          googleAddress: stepTwo.address,
          wgs84: stepTwo.position,
        },
      },

      scope: stepOne.damageCategory,

      cableInformation: {
        optoDetails: {
          optoDamage: stepOne.damage.fiber === t(FiberOptions.FIBER_IN_SEWER),
          tubingDamage: stepOne.damage.fiber === t(FiberOptions.SEWER_ONLY),
        },
        copperDetails: {
          plasticSheathDamage: stepOne.damage.copper === t(CopperOptions.PLASTIC_SHEATHED),
          leadSheathDamage: stepOne.damage.copper === t(CopperOptions.LEAD_SHEATHED),
          tubingDamage: stepOne.damage.copper === t(CopperOptions.ABOVE_CHANNELING),
        },
        cableWidth: stepOne.cableWidth,
        lkTicket: stepOne.managementControlCaseNumber,
        isGuideCorrect: stepOne.isGuideCorrect,
        dateOfIncident: stepOne.dateOfIncident,
        dateOfRefill: stepOne.dateOfRefill,
      },
    });

    const issueBlob = new Blob([jsonIssueData], {
      type: 'application/json;charset=UTF-8',
    });

    formData.append('issue', issueBlob);
    formData.append('id', sessionId);

    const fileNames = selectedFiles.map((file) => file.name);
    const blob = new Blob([JSON.stringify(fileNames)], {
      type: 'application/json;charset=UTF-8',
    });
    formData.append('files', blob);

    return formData;
  }

  async function sendForm() {
    setIsSendingRequest(true);

    const response = await fetch(HOST + '/api/report', {
      method: 'POST',
      body: createFormData(),
    });

    if (response.status !== 201) {
      throw new Error(response.statusText);
    }
  }

  function isStepSkipped(step: number) {
    return skipped.has(step);
  }

  async function handleNext() {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (
      activeStep === steps.length &&
      validateFormStep(1) &&
      validateFormStep(2) &&
      validateFormStep(3)
    ) {
      try {
        setIsError(false);
        await sendForm();
      } catch (err) {
        setIsError(true);
        return;
      } finally {
        setIsSendingRequest(false);
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    scrollToTop();
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  }

  function handleReset() {
    setActiveStep(1);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useHideChatBot();

  useEffect(() => {
    if (activeStep > steps.length) {
      navigate('/anmal-skada/tack');
    }
  }, [navigate, activeStep, steps.length]);

  return (
    <div className="report-wizard">
      <Stepper />
      <ReportForm
        handleReset={handleReset}
        handleBack={handleBack}
        handleNext={handleNext}
        isError={isError}
      />
    </div>
  );
}
