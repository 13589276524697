import { Helmet } from 'react-helmet-async';

export type SEOProps = {
  title: string;
  meta: {
    description: string;
    keywords: string;
    robotsTag: string;
    noIndex: boolean;
    noFollow: boolean;
  };
  socialMedia: {
    title: string;
    description: string;
    image: {
      url: string;
    };
  };
};

export default function SEO({ title, meta, socialMedia }: SEOProps) {
  function createRobotsContent() {
    const content = [];

    if (meta?.noIndex) {
      content.push('noindex');
    } else {
      content.push('index');
    }

    if (meta?.noFollow) {
      content.push('nofollow');
    } else {
      content.push('follow');
    }

    if (meta?.robotsTag) {
      content.push(`unavailable_after: ${meta.robotsTag}`);
    }

    return content.join(',');
  }

  return (
    <Helmet>
      <title>{title}</title>

      <meta name="description" content={meta.description} />
      {meta.keywords && <meta name="keywords" content={meta.keywords} />}

      {(meta.noIndex || meta.noFollow || meta.robotsTag) && (
        <meta name="robots" content={createRobotsContent()} />
      )}

      {socialMedia.title && <meta property="og:title" content={socialMedia.title} />}
      {socialMedia.description && (
        <meta property="og:description" content={socialMedia.description} />
      )}
      {socialMedia.image.url && <meta property="og:image" content={socialMedia.image.url} />}

      {socialMedia.title && <meta name="twitter:title" content={socialMedia.title} />}
      {socialMedia.description && (
        <meta name="twitter:description" content={socialMedia.description} />
      )}
      {socialMedia.image.url && <meta name="twitter:image" content={socialMedia.image.url} />}
    </Helmet>
  );
}
