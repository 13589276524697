import { PropsWithChildren } from 'react';
import './Card.scss';

type Props = {
  className?: string;
  dataTestid?: string;
};

export default function Card({
  children,
  className = '',
  dataTestid = '',
}: PropsWithChildren<Props>) {
  return (
    <div className={`card ${className}`} data-testid={dataTestid}>
      {children}
    </div>
  );
}
