import { TeliaTextInput } from '@teliads/components/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

import { useDamageReportForm } from 'contexts/damage-report-form.context';
import { Place } from 'pages/DamageReportForm/types';
import './PlacesAutocomplete.scss';
import useClickOutside from 'utils/hooks/useClickOutside';

interface Props {
  onPlaceChanged: (place: Place) => void;
}

const PlacesAutocomplete = ({ onPlaceChanged }: Props) => {
  const { t } = useTranslation();
  const {
    ready,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'se' },
    },
  });
  const { stepTwo, setStepTwo } = useDamageReportForm();

  const searchContainerRef = useRef<HTMLDivElement>(null);
  useClickOutside(searchContainerRef, clearSuggestions);

  const selectPlace = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = getLatLng(results[0]);

      onPlaceChanged({ location: { lat, lng }, address: address });
    } catch (err) {
      console.error('Something went wrong while trying to receive coordinates: ', err);
    }
  };

  return (
    <div className="search-container" ref={searchContainerRef}>
      <TeliaTextInput
        required
        requiredErrorMessage={t('error_message.required')}
        value={stepTwo.address}
        type="text"
        label={`1. ${t('damage_report_form.step_two.search_by_address')}`}
        onInput={(e) => {
          const address = (e.target as HTMLInputElement).value;

          setStepTwo((prev) => ({
            ...prev,
            address,
            position: {
              latitude: undefined,
              longitude: undefined,
            },
          }));
          setValue(address);
        }}
        disabled={!ready}
        className="search-container__search-input"
      />
      {status === 'OK' && (
        <div className="search-container__popover-container">
          <ul>
            {data.map(({ place_id, description }) => (
              <li key={place_id} onClick={() => selectPlace(description)}>
                <span>{description}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default React.memo(PlacesAutocomplete);
