import {
  ExecutionPatchIncrementalResult,
  ExecutionPatchInitialResult,
  SingleExecutionResult,
} from '@apollo/client';
import { formatInTimeZone } from 'date-fns-tz';

export const SCROLL_STOP = 'scroll-stop';

export function isMobileView(): boolean {
  const element = document.querySelectorAll('[data-mobile-detector]')[0];
  return !!(element && getComputedStyle(element).display !== 'none');
}

export default function replaceContentfulAssetsHost(
  response: SingleExecutionResult | ExecutionPatchInitialResult | ExecutionPatchIncrementalResult
) {
  return response;
}

export function disableScroll() {
  document.body.classList.add(SCROLL_STOP);
}

export function enableScroll() {
  document.body.classList.remove(SCROLL_STOP);
}

export function generateArrayWithUniqueEntries<T>(list: T[]): T[] {
  return Array.from(new Set(list));
}

export function formatDateToUTC(date: string, dateFormat?: string): string {
  return formatInTimeZone(new Date(date), 'UTC', dateFormat || 'yyyy-MM-dd');
}

export function getSlugFromLocationPath(pathname: string): string | undefined {
  return pathname.split('/').pop();
}

export function getEntryId(data: any) {
  if (data?.svData?.items.length) {
    return data?.svData?.items[0].sys.id;
  }

  if (data?.enData?.items.length) {
    return data?.enData?.items[0].sys.id;
  }
}

function getAspectRatioWidth(aspectRatio: `${string}:${string}`, height: number): number {
  const [widthAspect, heightAspect] = aspectRatio.split(':');
  return Math.ceil((height * Number(widthAspect)) / Number(heightAspect));
}

// Tuple values: [Aspect-Ratio, Height]
export type Dimensions = {
  small: [`${string}:${string}`, number];
  medium: [`${string}:${string}`, number];
  large: [`${string}:${string}`, number];
};

const defaultDimensions: Dimensions = {
  small: ['4:3', 450],
  medium: ['16:9', 650],
  large: ['16:9', 1125],
};

export function createMultipleSizeImages(
  {
    url,
    altText = '',
    focusArea,
  }: {
    url: string;
    altText?: string;
    focusArea?: string;
  },
  dimensions = defaultDimensions
) {
  const sizesInPixels = {
    small: [getAspectRatioWidth(...dimensions.small), dimensions.small[1]],
    medium: [getAspectRatioWidth(...dimensions.medium), dimensions.medium[1]],
    large: [getAspectRatioWidth(...dimensions.large), dimensions.large[1]],
  };
  const urlWithFocusArea = createUrlWithFocusArea(url, focusArea);

  return {
    default: urlWithFocusArea,
    small: `${urlWithFocusArea}&w=${sizesInPixels.small[0]}&h=${sizesInPixels.small[1]}`,
    medium: `${urlWithFocusArea}&w=${sizesInPixels.medium[0]}&h=${sizesInPixels.medium[1]}`,
    large: `${urlWithFocusArea}&w=${sizesInPixels.large[0]}&h=${sizesInPixels.large[1]}`,
    altText,
  };
}

export function createUrlWithFocusArea(url: string, focusArea?: string) {
  const FOCUS = focusArea || 'center';
  const RESIZE_STRATEGY = 'fill';
  const isUrlWithFocusArea = url.split('&f=').length === 2;

  if (isUrlWithFocusArea) {
    return url;
  } else {
    return `${url}&f=${FOCUS}&fit=${RESIZE_STRATEGY}`;
  }
}

export function parseNumericString(val: any) {
  return !isNaN(val) ? parseInt(val) : val;
}

export function capitalizeText(val: string) {
  return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
}
