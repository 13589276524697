import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Logo() {
  const { t } = useTranslation();

  const home = `/`;

  return (
    <Link to={home}>
      <img
        className="telia-logo"
        src="/image/Telia_Logotype_RGB_Purple.png"
        alt={t('telia_logo')}
        width="81px"
        height="100%"
      />
    </Link>
  );
}
