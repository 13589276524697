import { RouterProvider } from 'react-router-dom';

import { initI18n } from './i18n';
import { router } from './router';

export default function App() {
  initI18n();

  return <RouterProvider router={router} />;
}
