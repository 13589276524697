import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { PropsWithChildren } from 'react';

import replaceContentfulAssetsHost from 'utils/utils';

const contentfulEndpoint = process.env.REACT_APP_CONTENTFUL_ENDPOINT;
const contentDeliveryApiAccessToken =
  process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API_ACCESS_TOKEN;

const contentfulAssetsProxyLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    return replaceContentfulAssetsHost(response);
  });
});

const authMiddleware = (authToken: string) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

const httpLink = new HttpLink({ uri: contentfulEndpoint });
const baseLink = contentfulAssetsProxyLink.concat(httpLink);

const extendedLink = contentDeliveryApiAccessToken
  ? authMiddleware(contentDeliveryApiAccessToken).concat(baseLink)
  : baseLink;

const apolloClient = new ApolloClient({
  uri: contentfulEndpoint,
  cache: new InMemoryCache(),
  link: extendedLink,
  assumeImmutableResults: true,
});

export function ApolloCustomProvider({ children }: PropsWithChildren) {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
