import { useEffect } from 'react';

const useHideChatBot = () => {
  const widgets = document.getElementsByClassName(
    'humany-widget humany-active'
  ) as HTMLCollectionOf<HTMLDivElement>;
  const buttons = document.getElementsByClassName(
    'humany-trigger'
  ) as HTMLCollectionOf<HTMLDivElement>;

  const waitForChatbot = () => {
    const isActiveButton = buttons[0]?.classList?.contains('humany-active');
    const isWidgetLoaded = widgets.length > 0;
    const isButtonLoaded = buttons.length > 0;

    if (!isButtonLoaded || (!isWidgetLoaded && isActiveButton)) {
      setTimeout(waitForChatbot, 100);
    } else {
      buttons[0].style.display = 'none';

      if (isWidgetLoaded && isButtonLoaded && isActiveButton) {
        widgets[0].style.display = 'none';
      }
    }
  };

  const restoreChatBotVisibility = () => {
    if (buttons[0]) {
      buttons[0].style.display = 'block';
    }

    if (widgets[0]) {
      widgets[0].style.display = 'block';
    }
  };

  useEffect(() => {
    waitForChatbot();

    return () => {
      restoreChatBotVisibility();
    };
    // eslint-disable-next-line
  }, []);
};

export default useHideChatBot;
