import { TeliaCtaLink, TeliaGrid, TeliaHeading, TeliaP } from '@teliads/components/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PageNotFoundStateIcon } from 'assets/404-page-state.svg';
import { TFunction } from 'i18n';
import 'pages/Page404/Page404.scss';

export default function Page404() {
  const { t }: { t: TFunction } = useTranslation();

  return (
    <TeliaGrid className="page-404">
      <PageNotFoundStateIcon />

      <TeliaHeading tag="h1" variant="title-400" className="page-404__title">
        {t('404_page_title')}
      </TeliaHeading>

      <TeliaP>{t('404_page_content')}</TeliaP>

      <TeliaCtaLink href={`/`} className="page-404__cta">
        {t('home_page')}
      </TeliaCtaLink>
    </TeliaGrid>
  );
}
