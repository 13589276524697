import { QueryResult } from '@apollo/client/react/types/types';
import { ReactNode, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

import LoadingAnimation from 'components/custom/loading-screen/LoadingAnimation';

interface Props<T, U> {
  queryList: (QueryResult | UseQueryResult)[];
  mapperFn: (data: T) => U;
  children: (result: U) => ReactNode;
}

export default function PageContainer<T, U>({ queryList, mapperFn, children }: Props<T, U>) {
  const [loading, setLoading] = useState(false);
  const [combinedQueryData, setCombinedQueryData] = useState<T | null>(null);
  const allQueriesHaveData = queryList.map((query) => query.data).every((data) => !!data);
  const allQueriesFinishedLoading = queryList
    .map(
      (query) =>
        (Object.hasOwn(query, 'loading') && (query as QueryResult).loading) ||
        (Object.hasOwn(query, 'isLoading') && (query as UseQueryResult).isLoading)
    )
    .every((loading) => !loading);

  useEffect(() => {
    setLoading(true);

    if (allQueriesFinishedLoading && allQueriesHaveData) {
      const mappedData = queryList.map((query) => query.data) as T;
      setCombinedQueryData(mappedData);
      setLoading(false);
    }
  }, [queryList, allQueriesFinishedLoading, allQueriesHaveData]);

  if (loading || !combinedQueryData) {
    return <LoadingAnimation />;
  }

  const result = mapperFn(combinedQueryData);

  return <>{children(result)}</>;
}
