import {
  TeliaCol,
  TeliaForm,
  TeliaHeading,
  TeliaRow,
  TeliaTextarea,
} from '@teliads/components/react';
import { useTranslation } from 'react-i18next';

import MapWithSearch from '../map/MapWithSearch';
import './StepTwoForm.scss';

import Card from 'components/custom/card/Card';
import Paragraph from 'components/voca/paragraph/Paragraph';
import { useDamageReportForm } from 'contexts/damage-report-form.context';
import { MAX_TEXTAREA_LENGTH } from 'utils/constants';

export default function StepTwoForm() {
  const { t } = useTranslation();
  const { stepTwo, setStepTwo } = useDamageReportForm();

  function updateInput(which: keyof typeof stepTwo, value: string) {
    setStepTwo((prev) => ({
      ...prev,
      [which]: value,
    }));
  }

  return (
    <Card dataTestid="step-two-form-card">
      <TeliaForm name="step-two-form" className="step-two-form">
        <TeliaRow>
          <TeliaCol width={12} widthLg={8}>
            <TeliaHeading tag="h1" variant="title-200" className="step-two-form__title">
              {t('damage_report_form.step_two.title')}
            </TeliaHeading>
            <Paragraph variant="paragraph-100">
              {t('damage_report_form.step_two.description')}
            </Paragraph>
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12}>
            <MapWithSearch />
          </TeliaCol>
        </TeliaRow>

        <TeliaRow>
          <TeliaCol width={12} widthLg={6}>
            <TeliaTextarea
              maxlength={MAX_TEXTAREA_LENGTH}
              label={`2. ${t('damage_report_form.step_two.description_of_location')}`}
              required
              requiredErrorMessage={t('error_message.required')}
              rows={2}
              value={stepTwo.description}
              onInput={(e) => updateInput('description', (e.target as HTMLTextAreaElement).value)}
            />
          </TeliaCol>
        </TeliaRow>
      </TeliaForm>
    </Card>
  );
}
