import { renderPlainTextFromRichText } from './rich-text-render';

import { SEOProps } from 'components/custom/SEO/SEO';
import {
  CardArticleAssemblyFragment,
  CardAssemblyLevel2FaqFragment,
  CardAssemblyLevel2Fragment,
  CardTeaserFragment,
  SeoMetadataFragment,
} from 'graphql/__generated/sdk';
import { ContentfulTypename } from 'pages/Collection/types';
import type {
  BaseCardContent,
  ContentfulRichText,
  CtaContent,
  HeroBannerContent,
  ImageContent,
} from 'utils/models';

export interface Items<T> {
  items: T[];
}

export interface Image {
  image?: {
    url: string;
  };
  altText?: string;
}

interface BaseCard {
  title: string;
  url: string;
  image: Image | null;
  body?: any;
  category?: string;
  tagline?: string;
  buttonText?: string;
}

interface HeroBanner {
  title: string;
  preamble: ContentfulRichText;
  image: Image | null;
  videoUrl?: string;
  cta?: CtaContent;
}

interface Table {
  title: string;
  cta?: CtaContent;
  items?: any[];
}

function generateImage(image: Image): ImageContent {
  return {
    url: image.image?.url || '',
    altText: image.altText || '',
  };
}

export function generateBaseCard(card: BaseCard): BaseCardContent {
  return {
    title: card.title,
    url: card.url || '',
    image: generateImage(card.image || {}),
    body: card.body || '',
    buttonText: card.buttonText || '',
  };
}

export function generateHeroBanner(banner: HeroBanner): HeroBannerContent {
  return {
    title: banner.title,
    preamble: banner.preamble,
    imageUrl: banner.image?.image?.url || '',
    videoUrl: banner.videoUrl || '',
    cta: banner?.cta || {
      text: '',
      url: '',
    },
  };
}

export function generateTable(table: Table) {
  return {
    title: table.title,
    items: table.items || [],
    cta: table.cta || {
      text: '',
      url: '',
    },
  };
}

export function generateSeoMetadata(metadata: SeoMetadataFragment | null): SEOProps | null {
  if (metadata) {
    return {
      title: metadata.title,
      meta: {
        description: metadata.description,
        keywords: metadata.keywords?.join(', ') || '',
        robotsTag: metadata.xRobotsTag,
        noIndex: metadata.noIndex,
        noFollow: metadata.noFollow,
      },
      socialMedia: {
        title: metadata.socialMediaTitle || metadata.title,
        description: metadata.socialMediaDescription || '',
        image: generateImage(
          metadata.socialMediaImage ? metadata.socialMediaImage : { image: { url: '' } }
        ),
      },
    };
  }

  return null;
}

type AvailableCards =
  | CardArticleAssemblyFragment
  | CardTeaserFragment
  | CardAssemblyLevel2Fragment
  | CardAssemblyLevel2FaqFragment;

export function buildOneBaseCard(card: AvailableCards): BaseCardContent | undefined {
  switch (card.__typename) {
    case ContentfulTypename.ARTICLE_ASSEMBLY: {
      // TODO: Adjust this URL once we have full URL for articles
      const url = card.article.slug;

      return generateBaseCard({
        title: card.article.title,
        url,
        image: getFirstContentItem(card.article.mediaImageCollection),
        body: card.article.preamble || '',
      });
    }

    case ContentfulTypename.TEASER: {
      const cta = getFirstContentItem(card.callToActionCollection);

      return generateBaseCard({
        title: card.title,
        url: cta.linkTrigger,
        image: { image: card.primaryMediaImage.image },
        body: renderPlainTextFromRichText(card.richPreamble?.json) || '',
        buttonText: cta.ctaText,
      });
    }

    case ContentfulTypename.ASSEMBLY_LEVEL_2_FAQ:
    case ContentfulTypename.ASSEMBLY_LEVEL_2: {
      // TODO: This will probably need adjustments for level 2, somehow to have level 1 slug to know where the level 2 is?
      const url = card.slug;

      return generateBaseCard({
        title: card.heroBanner.title,
        url,
        image: { image: card.heroBanner.primaryMediaImage.image },
        body: renderPlainTextFromRichText(card.heroBanner.richPreamble?.json) || '',
      });
    }
  }
}

export function prepareBaseCards(content: {
  items: AvailableCards[];
}): (BaseCardContent | undefined)[] {
  return content.items.map((item) => buildOneBaseCard(item));
}

export function getFirstContentItem<T>(data: Items<T>): T {
  if (data.items.length) {
    return data.items[0];
  }

  return {} as T;
}
