import i18next from 'i18next';

import { InitialNavigationLink, NavigationClass, NavigationLink } from './models';

import { TFunction, initI18n } from 'i18n';
import { LANGUAGE_ENUM } from 'utils/constants';

initI18n();

function generateNavigationLinks(language: LANGUAGE_ENUM): InitialNavigationLink[] {
  return [
    {
      name: i18next.t('navigation.telia_som_natagare.title', { lng: language }),
      slug: i18next.t('navigation.telia_som_natagare.slug', { lng: language }),
    },
    {
      name: i18next.t('navigation.framtidens_nat.title', { lng: language }),
      slug: i18next.t('navigation.framtidens_nat.slug', { lng: language }),
    },
    {
      name: i18next.t('navigation.om_oss.title', { lng: language }),
      slug: i18next.t('navigation.om_oss.slug', { lng: language }),
    },
    {
      name: i18next.t('navigation.vanliga_fragor.title', { lng: language }),
      slug: i18next.t('navigation.vanliga_fragor.slug', { lng: language }),
    },
    {
      name: i18next.t('navigation.anmal-skada.title', { lng: language }),
      slug: i18next.t('navigation.anmal-skada.slug', { lng: language }),
      icon: 'flag',
    },
  ];
}

const navLinksMap = new Map<LANGUAGE_ENUM, InitialNavigationLink[]>([
  [LANGUAGE_ENUM.SWEDISH, generateNavigationLinks(LANGUAGE_ENUM.SWEDISH)],
]);

class NavigationStubClass extends NavigationClass {
  private navigationLinks: NavigationLink[] = [];
  private id = 1;
  private readonly dynamicTranslate: TFunction;

  constructor(language: LANGUAGE_ENUM) {
    super();
    this.dynamicTranslate = i18next.getFixedT(language);
    this.initNavLinks(language);
    this.buildNavigationLinks(language);
  }

  private buildNavigationLinks(
    language: LANGUAGE_ENUM,
    navLinks = this.navigationLinks,
    parentPath = ``,
    level = 1
  ): void {
    navLinks.forEach((route) => {
      const { children, slug, prefix, suffix, icon } = route;

      this.id += 1;

      const childPathBasedOnLevel = `${parentPath}/${slug}`;

      route.level = level;
      route.path = childPathBasedOnLevel;
      route.id = this.id;
      route.icon = icon;
      route.directPath = this.generateRouteDirectPath(
        level,
        language,
        slug,
        childPathBasedOnLevel,
        prefix,
        suffix
      );

      if (children) {
        const newLevel = level + 1;
        this.buildNavigationLinks(language, children, childPathBasedOnLevel, newLevel);
        children.unshift({
          name: route.name,
          path: '',
          directPath: this.generateRouteDirectPath(level, language, slug, '', prefix),
          level,
          id: ++this.id,
          slug: route.slug,
        });
      }
    });
  }

  private generateRouteDirectPath(
    level: number,
    language: LANGUAGE_ENUM,
    routeSlug: string,
    childPathBasedOnLevel: string,
    prefix: InitialNavigationLink['prefix'],
    suffix?: InitialNavigationLink['suffix']
  ): string {
    if (prefix === '') {
      return `/${routeSlug}`;
    }

    if (suffix && prefix) {
      return `/${this.dynamicTranslate(prefix)}/${this.dynamicTranslate(suffix)}/${routeSlug}`;
    }

    if (prefix && !suffix) {
      return `/${this.dynamicTranslate(prefix)}/${routeSlug}`;
    }

    return level > 1
      ? `/${this.dynamicTranslate('link_articles')}/${routeSlug}`
      : childPathBasedOnLevel;
  }

  private initNavLinks(language: LANGUAGE_ENUM): void {
    this.navigationLinks = JSON.parse(
      JSON.stringify(navLinksMap.get(language))
    ) as NavigationLink[];
  }

  public getLinks(): NavigationLink[] {
    return this.navigationLinks;
  }

  public getFlattenLinks(links = this.navigationLinks): NavigationLink[] {
    return links.flatMap((link) => {
      const { children } = link;
      const newLink = { ...link };
      return children?.length ? [newLink, ...this.getFlattenLinks(children)] : newLink;
    });
  }

  public findLinkBy(
    identifier: keyof Pick<NavigationLink, 'id' | 'path' | 'slug'>,
    identifierValue: string | number,
    links = this.navigationLinks
  ): NavigationLink | undefined {
    if (identifierValue === '') {
      return {
        name: this.dynamicTranslate('link_home'),
        children: this.navigationLinks,
      } as unknown as NavigationLink;
    }

    for (const node of links) {
      if (node[identifier] === identifierValue) {
        return node;
      }
      if (node.children) {
        const found = this.findLinkBy(identifier, identifierValue, node.children);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }
}

export default new Map<LANGUAGE_ENUM, NavigationClass>([
  [LANGUAGE_ENUM.SWEDISH, new NavigationStubClass(LANGUAGE_ENUM.SWEDISH)],
]);
