import { makeVar } from '@apollo/client';

import { NavigationClass } from 'components/voca/navigation/navigation-stub/models';
import NavigationStub from 'components/voca/navigation/navigation-stub/NavigationStub';
import { CONTENTFUL_LANGUAGE_ENUM, LANGUAGE_ENUM } from 'utils/constants';

export const urlLanguage = makeVar<LANGUAGE_ENUM>(LANGUAGE_ENUM.SWEDISH);
export const contentfulLanguage = makeVar<CONTENTFUL_LANGUAGE_ENUM>(
  CONTENTFUL_LANGUAGE_ENUM.SWEDISH
);
export const currentNavStub = makeVar<NavigationClass>(NavigationStub.get(LANGUAGE_ENUM.SWEDISH)!);
