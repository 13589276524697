import { TeliaCtaLink } from '@teliads/components/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alignment } from 'components/custom/info-box/types';
import Heading from 'components/voca/heading/Heading';
import Paragraph from 'components/voca/paragraph/Paragraph';
import { ButtonVariantEnum } from 'utils/constants';

import 'components/custom/info-box/InfoBox.scss';

interface Props {
  alignment?: Alignment;
  buttonVariant?: string;
  title: string;
  text: any;
  buttonText?: string;
  link?: string;
}

export const InfoBox: React.FC<Props> = ({
  alignment = Alignment.LEFT,
  buttonVariant = ButtonVariantEnum.EXPRESSIVE,
  title,
  text,
  buttonText = '',
  link,
}) => {
  const { t } = useTranslation();

  const ctaText = buttonText ? buttonText : t('read_more');

  return (
    <div className={`info-box info-box--${alignment}`} data-testid="info-box">
      <div className="info-box__wrapper">
        <section className="info-box__section">
          <Heading tag="h3" variant="title-500" className="info-box__title">
            {title}
          </Heading>

          <Paragraph
            variant="paragraph-100"
            className={`info-box__text${!link ? ' info-box__text--without-margin' : ''}`}
          >
            {text}
          </Paragraph>
        </section>

        {link && (
          <TeliaCtaLink
            // @ts-ignore
            variant={buttonVariant}
            className="info-box__button"
            size="md"
            dataTestid="info-box-button"
            href={link}
          >
            {ctaText}
          </TeliaCtaLink>
        )}
      </div>
    </div>
  );
};
