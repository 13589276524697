import { NavigationLink } from '../navigation/navigation-stub/models';
import NavigationStub from '../navigation/navigation-stub/NavigationStub';

import { LANGUAGE_ENUM } from 'utils/constants';

function buildNavigationLinks(nav: NavigationLink) {
  if (isDropdown(nav)) {
    return buildDropdownButton(nav);
  } else {
    return buildDirectLink(nav);
  }
}

function buildDropdownButton(nav: NavigationLink) {
  const sectionsWithoutFirstSection = nav.children ? nav.children.slice(1) : [];

  return {
    children: sectionsWithoutFirstSection.map((navChild) => buildSection(navChild, nav)),
    url: nav.directPath,
    name: nav.name,
  };
}

function buildDirectLink(nav: NavigationLink, parentNav = {} as NavigationLink) {
  const linkObject: { url: string; name: string; icon?: string } = {
    url: nav.directPath || parentNav.directPath,
    name: nav.name,
  };

  if (nav.icon) {
    linkObject.icon = nav.icon;
  }

  return linkObject;
}

function buildSection(nav: NavigationLink, parentNav: NavigationLink) {
  const sectionInfo: {
    links: any[];
    heading?: string;
    sectionLink?: {
      title: string;
      url: string;
    };
  } = {
    links: buildSectionNavLinks(nav),
  };

  if (nav.name !== '') {
    sectionInfo.heading = nav.name;
    sectionInfo.sectionLink = {
      title: nav.name,
      url: nav.directPath || parentNav.directPath,
    };
  }

  return sectionInfo;
}

function buildSectionNavLinks(nav: NavigationLink) {
  const linksWithoutSectionLink = nav.children ? nav.children.slice(1) : [];

  return linksWithoutSectionLink.map((navChild) => ({
    title: navChild.name,
    url: navChild.directPath || nav.directPath,
  }));
}

function isDropdown(nav: NavigationLink) {
  return nav.children && nav.children?.length > 0;
}

export function generateLinksWithSections(urlLanguage: LANGUAGE_ENUM) {
  const navigationLinks = NavigationStub.get(urlLanguage)!.getLinks();
  return navigationLinks.map((nav: NavigationLink) => buildNavigationLinks(nav));
}
