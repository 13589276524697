import { TeliaIcon } from '@teliads/components/react';

import './Stepper.scss';
import { useDamageReportForm } from 'contexts/damage-report-form.context';

export default function Stepper() {
  const { steps, activeStep } = useDamageReportForm();

  const getStepStatus = (index: number) => {
    const currentStep = index + 1;

    if (activeStep === currentStep) return 'active';
    if (activeStep > currentStep) return 'completed';
    return 'disabled';
  };

  return (
    <div className="stepper">
      {steps.map((label, index) => (
        <div key={index} className="stepper__item" data-testid="stepper-item">
          <div
            className={`stepper__circle stepper__circle--${getStepStatus(index)}`}
            data-testid="stepper-circle"
          >
            {activeStep > index + 1 ? (
              <TeliaIcon name="checkmark" size="sm" data-testid="circle-icon" />
            ) : (
              index + 1
            )}
          </div>

          <span className={`stepper__label stepper__label--${getStepStatus(index)}`}>{label}</span>

          {index < steps.length - 1 && <div className="stepper__line" data-testid="stepper-line" />}
        </div>
      ))}
    </div>
  );
}
