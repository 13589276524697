import { TeliaGrid } from '@teliads/components/react';

import { Alignment, InfoBox } from '../../components/custom/info-box';
import { ButtonVariantEnum } from '../../utils/constants';

import type { PageLevel0Props } from './models';

import { BaseCard, CardVariantEnum } from 'components/custom/base-card';
import Carousel from 'components/custom/carousel/Carousel';
import SEO from 'components/custom/SEO/SEO';
import Heading from 'components/voca/heading/Heading';
import HeroBanner from 'components/voca/hero-banner/HeroBanner';
import { renderPlainTextFromRichText } from 'utils/rich-text-render';

import './PageLevel0.scss';

export default function PageLevel0Template({
  heroBanner,
  infoBox,
  topCardsDescription,
  topCards,
  bottomCards,
  seoMetadata,
}: PageLevel0Props) {
  const shouldRenderCarousel = bottomCards.items.length > 1;

  return (
    <>
      {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          meta={seoMetadata.meta}
          socialMedia={seoMetadata.socialMedia}
        />
      )}
      <div className="page-level-0__hero-banner">
        <HeroBanner
          title={heroBanner.title}
          preamble={renderPlainTextFromRichText(heroBanner.preamble.json)}
          video={
            heroBanner.videoUrl || heroBanner.imageUrl
              ? { url: heroBanner.videoUrl, poster: heroBanner.imageUrl }
              : undefined
          }
        />
      </div>
      <TeliaGrid>
        <InfoBox
          alignment={Alignment.CENTER}
          title={infoBox.title}
          text={renderPlainTextFromRichText(infoBox.preamble.json)}
          link={infoBox.cta?.url}
          buttonText={infoBox.cta?.text}
        />
      </TeliaGrid>

      <div
        className="page-level-0__mid-section"
        style={{ backgroundImage: `url(${topCardsDescription.imageUrl})` }}
      >
        <TeliaGrid>
          <InfoBox
            title={topCardsDescription.title}
            text={renderPlainTextFromRichText(topCardsDescription.preamble.json)}
            buttonText={topCardsDescription.cta?.text}
            buttonVariant={ButtonVariantEnum.PRIMARY}
          />

          <div className="page-level-0__top-cards-wrapper" data-testid="top-cards">
            {topCards.map((card, index: number) => (
              <BaseCard
                key={`${card.title}-${index}`}
                cardVariant={CardVariantEnum.SECONDARY_REGULAR}
                title={card.title}
                imgSrc={card.image.url}
                link={card.url}
              />
            ))}
          </div>
        </TeliaGrid>
      </div>

      <TeliaGrid className="page-level-0__bottom-cards-wrapper">
        <Heading tag="h2" variant="title-300" className="page-level-0__bottom-cards-heading">
          {bottomCards.title}
        </Heading>

        {shouldRenderCarousel ? (
          <Carousel>
            {bottomCards.items.map((card, index: number) => (
              <BaseCard
                key={`${card.title}-${index}`}
                cardVariant={CardVariantEnum.LARGE}
                title={card.title}
                imgSrc={card.image.url}
                link={card.url}
              />
            ))}
          </Carousel>
        ) : (
          bottomCards.items.map((card, index: number) => (
            <BaseCard
              key={`${card.title}-${index}`}
              cardVariant={CardVariantEnum.LARGE}
              title={card.title}
              imgSrc={card.image.url}
              link={card.url}
            />
          ))
        )}
      </TeliaGrid>
    </>
  );
}
