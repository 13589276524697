import { TeliaCol, TeliaGrid, TeliaRow } from '@teliads/components/react';
import { useState } from 'react';
import { useHeader } from 'telia-navigation';
import './ReportForm.scss';

import FileUpload from './file-upload/FileUpload';
import FormNavigation from './form-navigation/FormNavigation';
import StepOneForm from './step-one/StepOneForm';
import StepSummary from './step-summary/StepSummary';
import StepThreeForm from './step-three/StepThreeForm';
import StepTwoForm from './step-two/StepTwoForm';
import { FormNavigationHandlersWithError } from './types';

import { useDamageReportForm } from 'contexts/damage-report-form.context';

export default function ReportForm({
  handleBack,
  handleNext,
  isError,
}: Omit<FormNavigationHandlersWithError, 'handleExpansion'>) {
  const { activeStep } = useDamageReportForm();
  const { headerHeight } = useHeader();

  const [isExpanded, setIsExpanded] = useState(false);

  function renderCurrentForm() {
    switch (activeStep) {
      case 1: {
        return <StepOneForm />;
      }

      case 2: {
        return <StepTwoForm />;
      }

      case 3: {
        return <StepThreeForm />;
      }

      case 4: {
        return <StepSummary />;
      }
    }
  }

  function toggleExpansion() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="report-form">
      <TeliaGrid>
        <TeliaRow>
          <TeliaCol width={12} widthLg={8}>
            {renderCurrentForm()}
          </TeliaCol>

          <TeliaCol width={12} widthLg={4}>
            <div
              className="form-right"
              style={{ '--header-height': `${headerHeight}px` } as { [key: string]: string }}
            >
              <FileUpload isExpanded={isExpanded} />
              <FormNavigation
                handleNext={handleNext}
                handleBack={handleBack}
                isExpanded={isExpanded}
                handleExpansion={toggleExpansion}
                isError={isError}
              />
            </div>
          </TeliaCol>
        </TeliaRow>
      </TeliaGrid>
    </div>
  );
}
