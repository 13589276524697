import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import GlideWrapper from './GlideWrapper';
import { CarouselSettings } from './types';

export default function Carousel({ children, ...props }: PropsWithChildren<CarouselSettings>) {
  const gridRef = useRef<HTMLDivElement>(null);
  const [, setElementSize] = useState(0);

  // This useEffect is used to trigger GlideWrapper update only
  useEffect(() => {
    if (gridRef.current) {
      const resizeListener = () => {
        if (gridRef.current?.offsetWidth) {
          setElementSize(gridRef.current?.offsetWidth);
        }
      };

      const resizeObserver = new ResizeObserver(resizeListener);

      resizeObserver.observe(gridRef.current);

      return () => resizeObserver.disconnect();
    }
  }, [gridRef]);

  return (
    <div ref={gridRef}>
      <GlideWrapper {...props}>{children}</GlideWrapper>
    </div>
  );
}
