import { getMappedPageContent } from './mappers';
import { DataEntry, PageLevel0Props } from './models';
import PageLevel0Template from './PageLevel0Template';

import PageContainer from 'components/custom/page-container/PageContainer';
import {
  GetAssemblyLevel0CollectionDocument,
  GetAssemblyLevel0CollectionQuery,
} from 'graphql/__generated/sdk';
import useI18nQuery from 'utils/hooks/useI18nQuery';

export default function PageLevel0() {
  const query = useI18nQuery<GetAssemblyLevel0CollectionQuery>(GetAssemblyLevel0CollectionDocument);

  return (
    <PageContainer<DataEntry, PageLevel0Props> queryList={[query]} mapperFn={getMappedPageContent}>
      {(result) => (
        <PageLevel0Template
          heroBanner={result.heroBanner}
          topCardsDescription={result.topCardsDescription}
          topCards={result.topCards}
          bottomCards={result.bottomCards}
          seoMetadata={result.seoMetadata}
          infoBox={result.infoBox}
        />
      )}
    </PageContainer>
  );
}
